import React, { useState, useRef } from 'react';
import Lottie from "lottie-react";
import scrollAnimation from "./lf20_vedruydr.json";
import finishAnimation from "./lf20_35l5nqmd.json";
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import Collapse from "@kunukn/react-collapse";
import * as cx from 'classnames'
import { ReactComponent as Skip } from './assets/skip-forward.svg';
import { ReactComponent as Play } from './assets/play.svg';
import { ReactComponent as Pause } from './assets/pause.svg';

const Station = ({station, isActive, isLast}) => {
  const [index, setIndex] = useState(0)
  if (!isActive && index !== 0) {
    setIndex(0)
  }
  const lottieRef = useRef();

  const onToggle = newIndex =>  setIndex(index === newIndex ? index : newIndex)

//   const station = {
//       image: 'https://miro.medium.com/max/1000/0*M_ZI4yypaPXSBNXL.',
//       modules: [
//           {
//               name: "Einleitung",
//               audioUrl: "https://app.assets.cultway.io/e4a96ec9-2bde-4c41-b5a4-c57a160c41a7"
//           },
//           {
//             name: "Geschichte",
//             audioUrl: "https://app.assets.cultway.io/e4a96ec9-2bde-4c41-b5a4-c57a160c41a7"
//         }
//       ]
//   }
  return <div className="station" >
      <div>
    <p>&nbsp;</p>
    <img
        className="guide-image"
        src={station.image}
        style={{ listStyle: 'none' }}
        alt={`Slidea`}
    />
    </div>
    <section className="app">
    <div className="modules-wrapper">
    {    
    station.modules.map((module,i) => {
        return isVisible(i, index) && <div key={`module-${i}`} className={cx("item", { "item--active": index === i })}>
            <button className={`btn module-element ${index === i ? '': 'truncate'}`} onClick={() => onToggle(i)}>
                <span className="icon-text"><div className="icon baseline">{index !== i && <Skip className=""/>}</div>{module.name}</span>
            </button>
            <Collapse
                className="collapse"
                isOpen={index === i}
                onChange={({ state }) => {
                } }
                onInit={({ state }) => {
                } }
                render={collapseState => <div className={`content ${collapseState}`}>
                {isActive && index === i && <div className="module-element"> <AudioPlayer
                  
                  customIcons={{
                    play: <div className=" control-icon"><Play/></div>,
                    pause: <div className=" control-icon"><Pause/></div>,
                  }}
                  layout={'horizontal-reverse'}
                  customVolumeControls={[]}
                  customAdditionalControls={[]}
                  showSkipControls={false}
                  showJumpControls={false}
                  customProgressBarSection={[ 
                    RHAP_UI.PROGRESS_BAR, RHAP_UI.CURRENT_LEFT_TIME
                  ]}
                  autoPlay={i !== 0}
                  src={module.audioUrl}
                  onPlay={e => console.log("onPlay")}
                  onEnded={e => {console.info("ended")
                    setIndex(index + 1)}}
                  // other props here
                /></div>}
                {/* {index !== station.modules.length - 1 && <hr style={{color: '#F7F7F7', margin:'0px', borderLeftWidth: '0px', borderRightWidth: '0px', borderTop: '8px solid #F7F7F7'}}></hr>} */}
              </div>}
               />
        </div>;
    }
    )
    
    }

    </div>
    </section>
    {index === station.modules.length && !isLast && <><center><p>Nach links ziehen, um zur nächsten Station zu gelangen</p>
        <Lottie  className="lottie-drag" lottieRef={lottieRef} animationData={scrollAnimation} /></center>
    </>}
    {
    index === station.modules.length && isLast && <><center><p>Guide beendet!</p>
    <Lottie loop={false} className="lottie-drag" animationData={finishAnimation} />
    
    </center>
    </>
    
    }

  </div>
}

function isVisible(element, index) {
    return element === index || element === index+1
}

export default Station