import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard, Scrollbar, Mousewheel, Navigation, Pagination, Controller, Thumbs } from 'swiper';
// import axios from 'axios'
// import Lottie from "lottie-react";
// import scrollAnimation from "./scrollAnimation.json";
import 'swiper/swiper-bundle.css';

// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
import Station from './Station'
import './styles.scss';

SwiperCore.use([Keyboard, Scrollbar, Mousewheel, Navigation, Pagination, Controller, Thumbs]);

function App() {
  // const urlSearchParams = new URLSearchParams(window.location.search);
  // const params = Object.fromEntries(urlSearchParams.entries());
  // const [article, setArticle] = useState({title: 'Artikel wird geladen'})
  // const [sectionData, setSectionData] = useState([])
  // const [selectedComplexity, setSelectedComplexity] = useState(['basic','advanced','expert'][Math.floor(Math.random()*3)])
  // // useEffect(() => {
  //   const effectFunction = async () => {
  //     params.id = params.id || '7bbb67f9-02a3-4d70-b84a-564c2148ab9b'
  //     if (params.id && !sectionData.length) {
  //       const result = await axios.get(`https://modular-cms.cultway.io/items/articles/${params.id}`)
  //       setArticle(result.data.data)
  //       const sectionD = await Promise.all(result.data.data.sections.map(async id => {
  //         const section = (await axios.get(`https://modular-cms.cultway.io/items/sections/${id}`)).data.data
  //         const moduleD = await Promise.all(section.modules.map(async (id) => {
  //           return (await axios.get(`https://modular-cms.cultway.io/items/modules/${id}`)).data.data
  //         }))
  //         section.modules = moduleD
  //         return section
  //       }))
  //       setSectionData(sectionD, [params.id])
  //       }
  //     }
  //     effectFunction()
  // })

  // const lottieRef = useRef();
  // const slides = sectionData.map((data, i) =>
  //     <SwiperSlide key={`slide-${i}`} tag="div">
  //       <Swiper
  //         id={"vertical-"+i}
  //         tag="section"
  //         wrapperTag="div"
  //         direction='vertical'
  //         pagination={{
  //           type: "fraction"
  //         }}
  //         spaceBetween={100}
  //         slidesPerView={'auto'}
  //         keyboard
  //         mousewheel
  //         onInit={(swiper) => console.log('Swiper initialized!', swiper)}
  //         onSlideChange={(swiper) => {
  //           console.log('Slide index changed to: ', swiper.activeIndex);
  //         }}
  //         onReachEnd={() => console.log('Swiper end reached')}
  //     >
  //       <SwiperSlide key={`slide-${i}`} tag="div">
  //         <div className="section-slide-wrapper">
  //           <center>
  //           <img
  //             src={`https://modular-cms.cultway.io/assets/${data.image}`}
  //             style={{ listStyle: 'none' }}
  //             alt={`Slide ${i}`}
  //           />
  //           <h2>{data.headline}</h2>
  //           {sectionData[i].modules && sectionData[i].modules.length >= 1 && <Lottie className="lottie-scroll" lottieRef={lottieRef} animationData={scrollAnimation} />}
  //           </center>
  //         </div>
  //       </SwiperSlide>
  //       {sectionData[i].modules
  //         .filter(module => module.complexity_selection.includes(selectedComplexity))
  //         .map((module, j) => {
  //           return <SwiperSlide key={`slide-v-${j}`} tag="div">
  //               <div className="module-wrapper">
  //                 <details>
  //                   <summary>{module.key_idea}</summary>
  //                   <p>{module.text}</p>
  //                   {/* <p>{module.complexity_selection}</p> */}
  //                 </details>
  //               </div>
  //             </SwiperSlide>})
  //         }
  //       </Swiper>
  //     </SwiperSlide>
  //   );
  const stations = [
    {
      title: 'Ein Dorf wird zur Geburtsstätte einer neuen Malerei',
      image: 'https://cdn.statically.io/img/app.assets.cultway.io/32743672-db8f-43ba-9ab0-62dce474c02e.jpg',
      modules: [
          {
            name: "Einleitung",
            audioUrl: "https://app.assets.cultway.io/ec6c03dd-166b-4785-ad04-437513aac6d0"
          },
          {
            name: "Über den Ort Murnau",
            audioUrl: " https://app.assets.cultway.io/685d0b88-928b-499b-96c7-14882bda5b80"
          },
          {
            name: "Komposition des Gemäldes",
            audioUrl: "https://app.assets.cultway.io/dce035af-a499-4c05-aad9-63d4abe14b20"
          },
          {
            name: "Malweise der Künstler*innen",
            audioUrl: "https://app.assets.cultway.io/fe151a0e-c17e-4701-8eec-4c2398fb787c"
          }
      ]
  },
  {
    title: 'Eine temperamentvolle Frau verhilft zum Durchbruch',
    image: 'https://cdn.statically.io/img/app.assets.cultway.io/6f371f19-723f-4534-9e71-26602cd67ed7.jpg',
    modules: [
        {
            name: "Einleitung",
            audioUrl: "https://app.assets.cultway.io/e7aeaf45-6dca-4f0c-a15a-481a75415e73"
        }, 
        {
          name: "Der rosafarbene Salon",
          audioUrl: "https://app.assets.cultway.io/ae7a20db-0dcc-43ad-aba7-947291a1984c"
        },
        {
          name: "Über Marianne von Werefkin",
          audioUrl: "https://app.assets.cultway.io/9ceb1dcb-1a65-446e-ab13-2c4835999410"
        },
        {
          name: "Das Selbstbildnis von Marianne von Werefkin",
          audioUrl: "https://app.assets.cultway.io/747687a4-813a-45ee-9e04-ec37bed8812e"
        }
    ]
  },
  {
    title: 'Der Blaue Reiter nimmt Form an',
    image: 'https://cdn.statically.io/img/app.assets.cultway.io/24b3e6f3-384d-425a-9aae-7c7cf8af7f78.jpg',
    modules: [
        {
            name: "Einleitung",
            audioUrl: "https://app.assets.cultway.io/3cd73ea7-cef1-4ea0-9240-f816f27e10aa"
        },
        {
          name: "Der Konflikt in der Neuen Künstlervereinigung München",
          audioUrl: "https://app.assets.cultway.io/57edc20f-1b0d-4129-9fd7-8419772b26c0"
       },
       {
         name: "Die Arbeit von Franz Marc",
         audioUrl: "https://app.assets.cultway.io/5d8d8b73-2cdb-46d8-a2c0-cbbf040ba7ee"
      },
      {
        name: "Das Gemälde Tiger",
        audioUrl: "https://app.assets.cultway.io/14dfb21f-5f38-44d5-82a4-aef441a906bd"
     },
     {
       name: "Der Almanach des Blauen Reiters",
       audioUrl: "https://app.assets.cultway.io/664210c2-a5bd-4c0a-9773-95b50be6f2d9"
    }
    ]
  },
  {
    title: 'Die Künstlergruppe findet ein jähes Ende',
    image: 'https://cdn.statically.io/img/app.assets.cultway.io/e43cb648-93ee-4cef-8504-bdeee118652e.jpg',
    modules: [
        {
            name: "Einleitung",
            audioUrl: "https://app.assets.cultway.io/34839ae7-d128-42fe-8e68-19a0d8780d3f"
        },
        {
          name: "Der Blaue Reiter nach dem Krieg",
          audioUrl: "https://app.assets.cultway.io/4e1f5155-5a66-42e3-bbef-1bf60edb921e"
      },
      {
        name: "Komposition des Gemäldes",
        audioUrl: "https://app.assets.cultway.io/ab9a2a16-e274-4f56-ab0d-5a9d6da43596"
    },
    {
      name: "Interpretation des Gemäldes",
      audioUrl: "https://app.assets.cultway.io/5249ff26-0c93-472f-b9c0-0d2d0315c34f"
  }
    ]
  }
  ]
  return (
    <React.Fragment >
      <div className="center">
        <div className="total-wrapper">
          <Swiper
            id="main"
            tag="section"
            wrapperTag="div"
            direction='horizontal'
            // navigation
            pagination={{
              type: "fraction"
            }}
            spaceBetween={0}
            slidesPerView={1}
            keyboard
            onInit={(swiper) => console.log('Swiper initialized!', swiper)}
            onSlideChange={(swiper) => {
              console.log('Slide index changed to: ', swiper.activeIndex);
            }}
            onReachEnd={() => console.log('Swiper end reached')}
          >
            {stations.map((station, i) => 
            <SwiperSlide key={`slide-${i}`} tag="div">
              {({ isActive }) => (<Station isActive={isActive} station={station} isLast={i === stations.length - 1}/>)}
            </SwiperSlide>
            )}
          </Swiper>
        </div>
      </div>
    </React.Fragment>
  );
}


export default App;
